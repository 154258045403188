import React, { useEffect } from 'react';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import Theme from '../theme';
import { graphql, StaticQuery } from "gatsby";

const CustomerChat = ()=>{

    useEffect(()=>{
        return()=>{
            const fbroot = document.getElementById("fb-root");
            if(fbroot) fbroot.remove();
        }
    });

    return(
        <StaticQuery 
            query={query}
            render={data=>{
                const setting = data.setting.edges[0].node.frontmatter;
                return(
                    <MessengerCustomerChat
                        pageId={setting.facebookPageId}
                        appId="2790985564285862"
                        themeColor={Theme.color.primary}
                        shouldShowDialog="true"
                        loggedInGreeting={setting.chatGreeting}
                        language="zh-tw"
                    />
                )
            }}
        />
    )
}

const query = graphql`
  query {
    setting: allMarkdownRemark( filter: { 
      frontmatter:{ type:{ eq: "setting" } }
    }){
      edges{
        node{
          frontmatter{
            chatGreeting
            facebookPageId
          }
        }
      }
    }
  }
`

export default CustomerChat;


