import React from 'react';
import styled from 'styled-components';
import Input, { Textarea, Select } from '../components/input';
import Button from './button';


const StyledForm = styled.form`
    margin: auto;
    width: 80%;
`;

const StyledButton = styled(Button)`
    display: block;
    margin: 40px auto;
`;

const BFInput = styled.input`
    display: none;
`;

const Form = ({data})=>{
    return(
        <StyledForm
            name="contact" 
            method="post" 
            data-netlify="true" 
            netlify-honeypot="bot-field"
            action='/success'
        >
            <input type="hidden" name="form-name" value="contact" />
            <BFInput name="bot-field" />
            <Input type='text' label='姓名' name='name' required/>
            <Input type='email' label='電子郵件' name='email' required />
            <Input type='phone' label='電話' name='phone' required />
            <Select 
                label='主題'
                name='subject'
                required
                options={data.subjectOptions}
            />
            <Textarea cols='50' rows='5' label='内容' maxLength='255' name='message' required />
            <StyledButton contrast text='確認送出' type='submit'/>
        </StyledForm>
    )
}

export default Form;