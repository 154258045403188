import React from 'react';
import styled from 'styled-components';

const Border = styled.span`
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: inherit;
    background-color: #3d79db;
    transform: scaleX(0);
    transform-origin: 0 0;
    transition: all .5s ease;
`;

const Label = styled.span`
    position: absolute;
    top: 10px;
    left: 0;
    font-size: 16px;
    color: ${({theme})=> theme ? theme.color.text.secondary :"#BBB"} ;
    font-weight: 500;
    transition: all .2s ease;
`;

const StyledInput = styled.input`
    -webkit-appearance: none;
    width: 100%;
    font-family: Nunito-Light;
    padding: 2px 0;
    height: 40px;
    font-size: 20px;
    font-weight: 500;
    border: 0;
    border-bottom: 1px solid #DDD;
    background: none;
    border-radius: 0;
    color: ${({theme})=> theme ? theme.color.text.secondary: "black" };
    transition: all .15s ease;
    :hover{
        background-color: rgba(100,100,100,0.3);
    }
    :not(:placeholder-shown){
        + span{
            transform: translateY(-26px) translateX(-5px) scale(.80);
        }
    }
    :focus{
        background: none;
        outline: none;
        + span{
            color: black;
            transform: translateY(-26px) translateX(-5px) scale(.80);
            + span{
                transform: scaleX(1);
            }
        }
    }
`;

const StyledTextarea = styled.textarea`
    -webkit-appearance: none;
    width: 100%;
    min-height: 40px;
    padding: 0;
    margin: 0 0 -4px 0;
    font-family: Nunito-Light;
    font-size: 20px;
    font-weight: 500;
    border: 0;
    border-bottom: 1px solid #DDD;
    background: none;
    border-radius: 0;
    color: ${({theme})=> theme ? theme.color.text.secondary: "black" };
    transition: all .15s ease;
    :hover{
        background-color: rgba(100,100,100,0.3);
    }
    :not(:placeholder-shown){
        + span{
            transform: translateY(-30px) translateX(-5px) scale(.80);
        }
    }
    :focus{
        background: none;
        outline: none;
        + span{
            color: black;
            transform: translateY(-30px) translateX(-5px) scale(.80);
            + span{
                transform: scaleX(1);
            }
        }
    }
`;

const Wrapper = styled.div`
    position: relative;
    margin: 30px auto;
    width: 100%;
    ${({width})=>width? `max-width: ${width};` : ''}
`;

const Input = ({type, label, required, width, ...props})=>(
    <Wrapper width={width}>
        <StyledInput type={type}  placeholder="&nbsp;" required={required} {...props}/>
        <Label>{label}</Label>
        <Border />
    </Wrapper>
);

const StyledSelect = styled.select`
    height: 40px;
    width: 80%;
    font-size: 18px;
    padding: 0 0 0 15px;
`;

const SelectWrapper = styled(Wrapper)`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

const Select =({name, width, label, required, options: opts})=>{
    return(
        <SelectWrapper width={width}>
            <StyledSelect name={name} required={required}>
                <option value="">--請選擇--</option>
                {opts.map(({label, options}, index)=>(
                    <optgroup key={index} label={label}>
                    {
                        options.map((option, index)=>(
                            <option key={index} value={option}>{option}</option>
                        ))
                    }
                    </optgroup>
                ))}
                <option value="其他">其他</option>
            </StyledSelect>
            <Label>{label}</Label>
        </SelectWrapper>
    )
}

const Textarea = ({label, required, cols, rows, maxLength, width, ...props})=>(
    <Wrapper width={width}>
        <StyledTextarea
            aria-invalid='false'
            data-gramm_editor="false"
            maxLength={maxLength}
            cols={cols} 
            rows={rows} 
            placeholder="&nbsp;" 
            required={required}
            {...props}
        />
        <Label>{label}</Label>
        <Border />
    </Wrapper>
);

export { Textarea, Select };

export default Input;