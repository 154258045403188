import React from 'react';
import styled from 'styled-components';
import TextCard from '../components/textCard';
import Chat from '../components/chat';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';
import Form from '../components/form';
import breakpoint from 'styled-components-breakpoint';
import SEO from '../components/seo';
import EmailLink from '../components/emailLink';


const TextCardWrapper = styled.div`
    padding: 100px 0 0 0;
`;

const Wrapper = styled.div`
    margin: 40px auto 80px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    ${breakpoint('tablet')`
        flex-direction: row;
        width: 60%;
    `}
    width: 90%;
`;

const Image = styled(Img)`
    width: 100%;
    height: 100%;
`;

const FormWrapper = styled.div`
    width: 100%;
    height: 100%;
`;

const ImageWrapper= styled.div`
    ${breakpoint('tablet')`
        width: 300px;
        height: 600px;
    `}
    width: 100%;
    height: 180px;
`;

const Contact = ({data})=>{
    const { title, address, email, phone, contactFormImage } = data.setting.edges[0].node.frontmatter;
    let subjectOptions = [
        {
            label: "服務項目",
            options: data.services.edges.map(({node})=>node.frontmatter.title)
        },
        {
            label: "產品銷售",
            options: data.products.edges.map(({node})=>node.frontmatter.title)
        }
    ];
    return(
        <div>
            <SEO title="聯絡我們"/>
            <TextCardWrapper>
                <TextCard 
                    title="聯絡我們"
                    description={(
                        <div>
                            <p>{title}</p>
                            <p>{address}</p>
                            <p>{phone}</p>
                            <p><EmailLink>{email}</EmailLink></p>
                        </div>
                    )}
                />
            </TextCardWrapper>
            <Wrapper>
                <ImageWrapper>
                    <Image fluid={contactFormImage.childImageSharp.fluid}/>
                </ImageWrapper>
                <FormWrapper>
                  <Form data={{subjectOptions}}/>
                </FormWrapper>
            </Wrapper>
            <Chat />
        </div>
    );
};

export default Contact;

export const query = graphql`
  query {
    setting: allMarkdownRemark( filter: { 
      frontmatter:{ type:{ eq: "setting" } }
    }){
      edges{
        node{
          frontmatter{
            title
            phone
            address
            email
            contactFormImage{
              childImageSharp{
                fluid(maxWidth: 400){
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
    services: allMarkdownRemark( filter: { 
      frontmatter:{ templateKey:{ eq: "service" } }
    }){
      edges{
        node{
          frontmatter{
            title
          }
        }
      }
    }
    products: allMarkdownRemark( filter: { 
      frontmatter:{ templateKey:{ eq: "product" } }
    }){
      edges{
        node{
          frontmatter{
            title
          }
        }
      }
    }
  }
`;